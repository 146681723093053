<script setup>
const check_types = ref(null);
const modalShowPodbor = ref(false)


const app_config = useAppConfig()
const type = ref([])
const getTypes = computed(() => {
    return type.value.map(e => e.label).join(',')
})

const formData = ref({
    name: '',
    email: '',
    phone: '',
    type: getTypes,
    location: app_config.city.rod,
    price_from: '',
    price_to: '',
    token: ''
});

const error = ref({
    name: '',
    email: '',

});



const validateName = () => {
    const regex = /^[a-zA-Zа-яА-ЯёЁ\s]+$/;
    if (!regex.test(formData.value.name)) {
        error.value.name = 'ФИО должно содержать только буквы и пробелы';
        formData.value.name = '';
    }
};

const validateEmail = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(formData.value.email)) {
        error.value.email = 'Введите корректный email';
        formData.value.email = '';
    }
};

const RECAPTCHA_PUBLIC = '6LehhpcqAAAAAPeAFBjLDowlFKhwp9vjStWtgGlF'

const handleSubmit = async () => {

    validateName();
    validateEmail();

    if (!error.value.name && !error.value.email) {
        try {

            grecaptcha.ready( () => {
                grecaptcha.execute( RECAPTCHA_PUBLIC, {action: 'submit'}).then( async (token) =>  {

                    formData.value.token = token

                    const response = await fetch('/api/forms/podbor', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(formData.value),
                    });

                    const result = await response.json();
                    if (result.status === 'success') {
                        // console.log(result);
                        // console.log('Форма успешно отправлена');
                        useToastify.success('Заявка отправлена', {
                            theme: 'colored',
                            autoClose: 5000,
                            position: ToastifyOption.POSITION.TOP_RIGHT,
                        });

                        ym(54350782,'reachGoal','podbor_submit')

                        formData.value = {
                            name: '',
                            email: '',
                            phone: '',
                            type: [],
                            location: app_config.city.rod,
                            price_from: '',
                            price_to: ''
                        };
                    } else if( result.status === 'error' ){
                        // console.error(result.errors);
                        useToastify.error('Заявка не отправлена', {
                            theme: 'colored',
                            autoClose: 5000,
                            position: ToastifyOption.POSITION.TOP_RIGHT,
                        });
                    }

                });
            });

        } catch (error) {
            // console.error('Ошибка отправки формы:', error);
            useToastify.error('Заявка не отправлена', {
                theme: 'colored',
                autoClose: 5000,
                position: ToastifyOption.POSITION.TOP_RIGHT,
            });
        }
    }
};

const props = defineProps({
    type: String
})


</script>
<template>

    <div class="mt-12 w-full gap-2 overflow-hidden rounded p-3 block bg-white">
        <h3 class="text-lg flex gap-2 items-center text-brand-grey">
            <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="" stroke-width="0"></g>
                <g id="" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="">
                    <path
                        d="M10.125 8.875C10.125 7.83947 10.9645 7 12 7C13.0355 7 13.875 7.83947 13.875 8.875C13.875 9.56245 13.505 10.1635 12.9534 10.4899C12.478 10.7711 12 11.1977 12 11.75V13"
                        stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path>
                    <circle cx="12" cy="16" r="1" fill="#1C274C"></circle>
                    <path
                        d="M7 3.33782C8.47087 2.48697 10.1786 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 10.1786 2.48697 8.47087 3.33782 7"
                        stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path>
                </g>
            </svg>
            Нужна помощь?
        </h3>
        <span class="inline-block pt-4 pb-[42px] text-sm text-brand-grey">
            Наши специалисты подберут недвижимость для Вас
        </span>

        <button :data-modal-target="`podbor_modal_${props.type}`" :data-modal-toggle="`podbor_modal_${props.type}`"
            type="button"
            class="w-full text-white bg-brand-primary py-1.5 px-4 md:py-2.5 md:px-12 rounded-lg font-medium hover:bg-brand-primary-hover">
            <p class="block text-base">Создать заявку</p>
        </button>

    </div>

    <div :id="`podbor_modal_${props.type}`" tabindex="-1" aria-hidden="true"
        class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-4 w-full max-w-2xl max-h-full">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <!-- Modal header -->
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 class="text-xl  text-brand-grey dark:text-white">
                        Подбор недвижимости
                    </h3>
                    <button type="button"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        :data-modal-hide="`podbor_modal_${props.type}`">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <!-- Modal body -->
                <div class="p-4 md:p-5 space-y-4">
                    <form @submit.prevent="handleSubmit" class="hidden sm:block mx-auto py-2">
                        <div class="mb-5 grid grid-cols-3 gap-4 items-center">
                            <label for="nameModal"
                                class="block mb-2 text-sm font-medium text-brand-grey dark:text-white col-span-1">ФИО<span
                                    class="text-red-500">*</span></label>
                            <span class="col-span-2 relative">
                                <input type="text" id="nameModal" v-model="formData.name"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light "
                                    placeholder="ФИО" required maxlength="255" />
                                <span class="absolute top-1 right-1 text-xs"
                                    :class="formData.name.length == 255 ? 'text-red-500' : 'text-gray-500', formData.name.length == 0 ? 'hidden' : 'block'">{{
                                        formData.name.length }}/255</span>
                                <span class="text-red-500 text-xs pl-1 pt-1" v-if="error.name">{{ error.name }}</span>
                            </span>

                            <label for="emailModal"
                                class="block mb-2 text-sm font-medium text-brand-grey dark:text-white col-span-1">Email<span
                                    class="text-red-500">*</span></label>
                            <span class="col-span-2 relative">
                                <input type="email" id="emailModal" v-model="formData.email"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                    placeholder="Введите email" required maxlength="255" />
                                <span class="absolute top-1 right-1 text-xs"
                                    :class="formData.email.length == 255 ? 'text-red-500' : 'text-gray-500', formData.email.length == 0 ? 'hidden' : 'block'">{{
                                        formData.email.length }}/255</span>
                                <span class="text-red-500 text-xs pl-1" v-if="error.email">{{ error.email }}</span>
                            </span>

                            <label for="phoneModal"
                                class="block mb-2 text-sm font-medium text-brand-grey dark:text-white col-span-1">Телефон<span
                                    class="text-red-500">*</span></label>




                            <!-- <InputMask id="phoneModal"
                                        class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" 
                                        mask="+7 (999) 999-99-99" :unstyled="true" fluid v-model="formData.phone"
                                        placeholder="Введите телефон" required /> -->
                            <!-- <InputMask  class="!border !shadow-sm !bg-gray-50  !border-gray-300 !text-gray-900 !text-sm !rounded-lg focus:!ring-blue-500 focus:border-blue-500 !block !w-full !p-2.5 dark:border-gray-600 placeholder:!text-gray-500 dark:text-white dark:focus:ring-blue-500 dark:shadow-sm-light" fluid :unstyled="true"  v-model="formData.phone" mask="+7 (999) 999-99-99" placeholder="Введите телефон" required/> -->

                            <!-- <input class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" v-maska="'+7 (999) 999-99-99'" v-model="formData.phone" placeholder="Введите телефон" required> -->
                            <input
                                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:!ring-blue-500 focus:border-2 focus:!border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light col-span-2 outline-none"
                                placeholder="Введите телефон" required v-maska="'+7 (###) ###-##-##'" v-model="formData.phone" 
                                data-maska-eager="true" />



                            <label for="typePropertyModal"
                                class="block mb-2 text-sm font-medium text-brand-grey dark:text-white col-span-1">Тип
                                недвижимости<span class="text-red-500">*</span></label>
                            <div class="col-span-2">
                                <UiSelectType v-model="type" type="form_podbor" />
                            </div>
                            <label for="locationModal"
                                class="block mb-2 text-sm font-medium text-brand-grey dark:text-white col-span-1">Расположение<span
                                    class="text-red-500">*</span></label>
                            <input type="text" id="locationModal"
                                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light col-span-2"
                                placeholder="Введите расположение" value="Санкт-Петербург" disabled required />
                            <label
                                class="block mb-2 text-sm font-medium text-brand-grey dark:text-white col-span-1">Цена<span
                                    class="text-red-500">*</span></label>
                            <div class="flex gap-2 items-center col-span-2">
                                <!-- <input type="number" id="priceFromModal" v-model="formData.price_from"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light "
                                    placeholder="Введите цену от" required /> -->
                                <!-- <InputNumber v-model="formData.price_from" :min="0" inputId="priceFromModal" showButtons class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light " required /> -->

                                <div class="relative flex items-center flex-1">
                                    <button type="button" id="decrement-buttonpriceFromModal"
                                        data-input-counter-decrement="quantity-inputpriceFromModal"
                                        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="M1 1h16" />
                                        </svg>
                                    </button>
                                    <input type="text" v-model="formData.price_from" id="quantity-inputpriceFromModal"
                                        data-input-counter data-input-counter-min="1"
                                        aria-describedby="helper-text-explanation"
                                        class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="от" required />
                                    <button type="button" id="increment-buttonpriceFromModal"
                                        data-input-counter-increment="quantity-inputpriceFromModal"
                                        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="M9 1v16M1 9h16" />
                                        </svg>
                                    </button>
                                </div>

                                <span>-</span>
                                <!-- <input type="number" id="priceToModal" v-model="formData.price_to"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light "
                                    placeholder="Введите цену до" required /> -->
                                <!-- <InputNumber v-model="formData.price_to" :min="0" inputId="priceToModal" showButtons  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light " required  /> -->

                                <div class="relative flex items-center flex-1">
                                    <button type="button" id="decrement-buttonpriceToModal"
                                        data-input-counter-decrement="quantity-inputpriceToModal"
                                        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="M1 1h16" />
                                        </svg>
                                    </button>
                                    <input type="text" v-model="formData.price_to" id="quantity-inputpriceToModal"
                                        data-input-counter data-input-counter-min="1"
                                        aria-describedby="helper-text-explanation"
                                        class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="до" required />
                                    <button type="button" id="increment-buttonpriceToModal"
                                        data-input-counter-increment="quantity-inputpriceToModal"
                                        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="M9 1v16M1 9h16" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end">
                            <button type="submit"
                                class="text-white bg-brand-primary hover:bg-brand-primary-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Отправить</button>
                        </div>
                    </form>
                    <!-- mobile form -->
                    <form @submit.prevent="handleSubmit" class="block sm:hidden max-w-lg mx-auto pb-6">
                        <div class="mb-5">
                            <label for="nameModal1"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white col-span-1">ФИО<span
                                    class="text-red-500">*</span></label>
                            <span class="col-span-2 relative">
                                <input type="text" id="nameModal1" v-model="formData.name"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light "
                                    placeholder="ФИО" required maxlength="255" />
                                <span class="absolute top-1 right-1 text-xs"
                                    :class="formData.name.length == 255 ? 'text-red-500' : 'text-gray-500', formData.name.length == 0 ? 'hidden' : 'block'">{{
                                        formData.name.length }}/255</span>
                                <span class="text-red-500 text-xs pl-1 pt-1" v-if="error.name">{{ error.name }}</span>
                            </span>
                            <label for="emailModal1"
                                class="block mb-2 mt-4 text-sm font-medium text-gray-900 dark:text-white col-span-1">Email<span
                                    class="text-red-500">*</span></label>
                            <span class="col-span-2 relative">
                                <input type="email" id="emailModal" v-model="formData.email"
                                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                    placeholder="Введите email" required maxlength="255" />
                                <span class="absolute top-1 right-1 text-xs"
                                    :class="formData.email.length == 255 ? 'text-red-500' : 'text-gray-500', formData.email.length == 0 ? 'hidden' : 'block'">{{
                                        formData.email.length }}/255</span>
                                <span class="text-red-500 text-xs pl-1" v-if="error.email">{{ error.email }}</span>
                            </span>
                            <label for="phoneModal1"
                                class="block mb-2 mt-4 text-sm font-medium text-gray-900 dark:text-white col-span-1">Телефон<span
                                    class="text-red-500">*</span></label>


                            <input
                                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:!ring-blue-500 focus:border-2 focus:!border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light col-span-2 outline-none"
                                placeholder="Введите телефон" required v-maska="'+7 (###) ###-##-##'" v-model="formData.phone" 
                                data-maska-eager="true" />


                            <!-- <InputMask id="phoneModal" class="!border !shadow-sm !bg-gray-50  !border-gray-300 !text-gray-900 !text-sm !rounded-lg focus:!ring-blue-500 focus:border-blue-500 !block !w-full !p-2.5 dark:border-gray-600 placeholder:!text-gray-500 dark:text-white dark:focus:ring-blue-500 dark:shadow-sm-light" fluid :unstyled="true"  v-model="formData.phone" mask="+7 (999) 999-99-99" placeholder="Введите телефон" required/> -->


                            <label for="typePropertyModal1"
                                class="block mb-2 mt-4 text-sm font-medium text-gray-900 dark:text-white col-span-1">Тип
                                недвижимости<span class="text-red-500">*</span></label>
                            <div class="col-span-2">
                                <UiSelectType v-model="type" />
                            </div>
                            <label for="locationModal1"
                                class="block mb-2 mt-4 text-sm font-medium text-gray-900 dark:text-white col-span-1">Расположение<span
                                    class="text-red-500">*</span></label>
                            <input type="text" id="locationModal1"
                                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light col-span-2"
                                placeholder="Введите расположение" value="Санкт-Петербург" disabled required />
                            <label
                                class="block mb-2 mt-4 text-sm font-medium text-gray-900 dark:text-white col-span-1">Цена<span
                                    class="text-red-500">*</span></label>
                            <div class="flex gap-2 items-center col-span-2">
                                <div class="relative flex items-center flex-1">
                                    <button type="button" id="decrement-buttonpriceFromModal1"
                                        data-input-counter-decrement="quantity-inputpriceFromModal1"
                                        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="M1 1h16" />
                                        </svg>
                                    </button>
                                    <input type="text" v-model="formData.price_from" id="quantity-inputpriceFromModal1"
                                        data-input-counter data-input-counter-min="1"
                                        aria-describedby="helper-text-explanation1"
                                        class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="от" required />
                                    <button type="button" id="increment-buttonpriceFromModal1"
                                        data-input-counter-increment="quantity-inputpriceFromModal1"
                                        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="M9 1v16M1 9h16" />
                                        </svg>
                                    </button>
                                </div>
                                <span>-</span>
                                <div class="relative flex items-center flex-1">
                                    <button type="button" id="decrement-buttonpriceToModal1"
                                        data-input-counter-decrement="quantity-inputpriceToModal1"
                                        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="M1 1h16" />
                                        </svg>
                                    </button>
                                    <input type="text" v-model="formData.price_to" id="quantity-inputpriceToModal1"
                                        data-input-counter data-input-counter-min="1"
                                        aria-describedby="helper-text-explanation1"
                                        class="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="до" required />
                                    <button type="button" id="increment-buttonpriceToModal1"
                                        data-input-counter-increment="quantity-inputpriceToModal1"
                                        class="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                                        <svg class="w-3 h-3 text-gray-900 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" d="M9 1v16M1 9h16" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end mt-10">
                            <button type="submit" :data-modal-hide="`podbor_modal_${props.type}`"
                                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Отправить</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>